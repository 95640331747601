import React from "react";
import PropTypes from "prop-types"; // Ensure PropTypes is installed
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; // Ensure this path is correct

// PrivateRoute Component to restrict access
const PrivateRoute = ({ redirectPath = "/login" }) => {
  const { currentUser, loading } = useAuth(); // Assume 'loading' indicates if auth is being fetched

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-700">Checking authentication...</p>
      </div>
    );
  }

  return currentUser ? <Outlet /> : <Navigate to={redirectPath} replace />;
};

// Prop types to enforce component structure
PrivateRoute.propTypes = {
  redirectPath: PropTypes.string, // Optional redirect path prop
};

export default PrivateRoute;
