// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { auth } from "../firebase"; // Ensure Firebase setup is correct
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Loading from "../components/Loading"; // Ensure path is correct

/**
 * Authentication Context for managing user authentication state.
 * @typedef {Object} AuthContextType
 * @property {Object|null} currentUser - Currently logged-in user or null.
 * @property {function(string, string): Promise<void>} login - Login function.
 * @property {function(): Promise<void>} logout - Logout function.
 * @property {string|null} error - Error message for authentication failures.
 */

// Create the context
export const AuthContext = createContext();

/**
 * Custom hook to access authentication context.
 * @returns {AuthContextType} The authentication context.
 */
export const useAuth = () => useContext(AuthContext);

/**
 * Provides authentication state and actions to children components.
 * @param {Object} props - React props.
 * @param {JSX.Element} props.children - Child components to wrap.
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [error, setError] = useState(null);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user || null);
      setLoadingAuthState(false);
    });

    return () => {
      unsubscribe(); // Cleanup subscription on unmount
      setCurrentUser(null); // Ensure state is reset if component unmounts
    };
  }, []);

  /**
   * Logs the user in using email and password.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @throws Will throw an error if login fails.
   */
  const login = async (email, password) => {
    setError(null); // Reset any previous errors
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error("Login failed:", err);
      setError(err.message); // Provide error feedback
      throw err; // Re-throw for higher-level handling if needed
    }
  };

  /**
   * Logs the user out and resets authentication state.
   */
  const logout = async () => {
    setError(null);
    try {
      await signOut(auth);
      setCurrentUser(null); // Clear user state on logout
    } catch (err) {
      console.error("Logout failed:", err);
      setError(err.message);
    }
  };

  // Render loading component while authentication state is initializing
  if (loadingAuthState) {
    return <Loading />;
  }

  // Provide context values to children
  return (
    <AuthContext.Provider value={{ currentUser, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};
