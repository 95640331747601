import dotenv from "dotenv";
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Font Awesome icons
import "./assets/styles/tailwind.css"; // Global styles
import "./firebase"; // Firebase setup

// Components and Providers
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute"; // Ensure this is correctly imported
import { AuthProvider } from "./contexts/AuthContext";
import { LLMProvider } from "./contexts/LLMContext";
import { UserProvider } from "./contexts/UserContext";
import MainLayout from "./layouts/MainLayout";

// Lazy-loaded views for better performance
const Index = lazy(() => import("./views/Index"));
const ModeSelectionPage = lazy(() => import("./views/ModeSelectionPage"));
const Journal = lazy(() => import("./views/auth/Journal"));
const CalendarPage = lazy(() => import("./views/CalendarPage"));
const Login = lazy(() => import("./views/auth/Login"));
const Register = lazy(() => import("./views/auth/Register"));
const Profile = lazy(() => import("./views/Profile"));
const OnboardingPage = lazy(() => import("./views/OnboardingPage"));
const NotFound = lazy(() => import("./views/NotFound"));

// Load environment variables from .env
dotenv.config();

// Create the root element for rendering the React app
const container = document.getElementById("root");
const root = createRoot(container);

// Render the entire app with all providers and routing
root.render(
  <React.StrictMode>
    <AuthProvider>
      <LLMProvider>
        <UserProvider>
          <Router>
            <Suspense fallback={<Loading />}>
              <Routes>
                {/* Public Pages with MainLayout wrapper */}
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Index />} />
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<Register />} />
                  <Route path="onboarding" element={<OnboardingPage />} />

                  {/* Protected Pages requiring authentication */}
                  <Route
                    path="profile"
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="journal"
                    element={
                      <PrivateRoute>
                        <Journal />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="calendar"
                    element={
                      <PrivateRoute>
                        <CalendarPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="modes"
                    element={
                      <PrivateRoute>
                        <ModeSelectionPage />
                      </PrivateRoute>
                    }
                  />

                  {/* Catch-all Route for 404 NotFound */}
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </Suspense>
          </Router>
        </UserProvider>
      </LLMProvider>
    </AuthProvider>
  </React.StrictMode>,
);
