// src/components/NotificationsPanel.js
import React from "react";

const notifications = [
  {
    id: 1,
    text: "Congratulations! You've maintained a 7-day streak!",
    type: "achievement",
  },
  {
    id: 2,
    text: "Reminder: Complete your journaling for today.",
    type: "reminder",
  },
];

export default function NotificationsPanel() {
  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-md">
      <h2 className="mb-4 text-xl font-bold">Notifications</h2>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="flex items-center justify-between p-2 bg-white rounded-md shadow-sm"
        >
          <span>{notification.text}</span>
          <button className="text-sm underline text-blue-500">
            Mark as Read
          </button>
        </div>
      ))}
    </div>
  );
}
