// FlowerProgress.js
import React from "react";

const FlowerProgress = () => {
  const progress = 75; // Replace with dynamic progress data

  return (
    <div className="flex justify-center my-10">
      <div className="relative">
        <svg
          className="w-48 h-48"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke="#E5E7EB"
            strokeWidth="10"
            fill="none"
          />
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke="#4F46E5"
            strokeWidth="10"
            fill="none"
            strokeDasharray={`${progress} 100`}
            strokeDashoffset="25"
          />
        </svg>
        <div className="absolute inset-0 flex justify-center items-center">
          <span className="text-xl font-semibold">{progress}%</span>
        </div>
      </div>
    </div>
  );
};

export default FlowerProgress;
