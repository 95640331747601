// src/contexts/LLMContext.js
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useReducer,
} from "react";
import Replicate from "replicate";

// Create LLMContext
export const LLMContext = createContext();

/**
 * Custom hook to access the LLM context.
 * @returns {Object} The LLM context values.
 */
export const useLLM = () => useContext(LLMContext);

// Helper function to make API calls to Replicate
const replicateRequest = async (modelId, inputParams) => {
  const replicate = new Replicate({
    auth: process.env.REACT_APP_REPLICATE_API_TOKEN,
  });

  try {
    const output = await replicate.run(modelId, { input: inputParams });
    return output;
  } catch (error) {
    throw new Error(`Replicate API Error: ${error.message}`);
  }
};

// Model IDs (Move to environment variables or config if needed)
const ANALYZE_MODEL_ID = "your-analysis-model-id";
const SUGGESTIONS_MODEL_ID = "your-suggestions-model-id";

// State reducer for managing feedback and suggestions
const initialState = {
  feedback: null,
  suggestions: [],
  loading: false,
  error: null,
};

const llmReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: true, error: null };
    case "SET_FEEDBACK":
      return { ...state, feedback: action.payload, loading: false };
    case "SET_SUGGESTIONS":
      return { ...state, suggestions: action.payload, loading: false };
    case "ERROR":
      return { ...state, error: action.payload, loading: false };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

// LLMProvider Component to wrap children with LLM context
export const LLMProvider = ({ children }) => {
  const [state, dispatch] = useReducer(llmReducer, initialState);

  /**
   * Analyze a journal entry and provide feedback.
   * @param {string} entryText - The journal entry text to analyze.
   */
  const analyzeEntry = useCallback(async (entryText) => {
    dispatch({ type: "LOADING" });

    try {
      const response = await replicateRequest(ANALYZE_MODEL_ID, {
        text: entryText,
      });
      dispatch({ type: "SET_FEEDBACK", payload: response.output });
    } catch (error) {
      dispatch({ type: "ERROR", payload: error.message });
    }
  }, []);

  /**
   * Generate writing suggestions based on the user's mood.
   * @param {string} mood - The user's mood.
   */
  const generateSuggestions = useCallback(async (mood) => {
    dispatch({ type: "LOADING" });

    try {
      const response = await replicateRequest(SUGGESTIONS_MODEL_ID, { mood });
      dispatch({ type: "SET_SUGGESTIONS", payload: response.output });
    } catch (error) {
      dispatch({ type: "ERROR", payload: error.message });
    }
  }, []);

  /**
   * Clear all feedback and suggestions.
   */
  const clearLLMData = () => dispatch({ type: "CLEAR" });

  /**
   * Retry logic for API requests with exponential backoff.
   * @param {Function} requestFunction - The function to retry.
   * @param {Array} args - Arguments to pass to the request function.
   * @param {number} retries - Number of retry attempts.
   * @param {number} delay - Initial delay in milliseconds.
   * @returns {Promise<any>} The result of the request function.
   */
  const retryRequest = async (
    requestFunction,
    args,
    retries = 3,
    delay = 1000,
  ) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await requestFunction(...args);
      } catch (error) {
        if (i === retries - 1) throw error; // Rethrow after max retries
        await new Promise((res) => setTimeout(res, delay * (i + 1))); // Exponential backoff
      }
    }
  };

  // Context values to provide
  const contextValue = {
    ...state,
    analyzeEntry,
    generateSuggestions,
    clearLLMData,
    retryRequest,
  };

  return (
    <LLMContext.Provider value={contextValue}>{children}</LLMContext.Provider>
  );
};
