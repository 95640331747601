/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-gradient-to-r from-blue-500 to-purple-600 shadow-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase tracking-wide hover:underline hover:scale-105 transition-all duration-200 ease-in-out shadow-lg"
            >
              Therapy Journal
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none hover:text-white"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link
                  to="/login"
                  className="hover:text-purple-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300 rounded"
                >
                  <i className="fas fa-sign-in-alt text-white text-lg leading-lg mr-2" />
                  Log In
                </Link>
              </li>

              <li className="flex items-center">
                <Link
                  to="/signup"
                  className="hover:text-purple-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300 rounded"
                >
                  <i className="fas fa-user-plus text-white text-lg leading-lg mr-2" />
                  Sign Up
                </Link>
              </li>

              <li className="flex items-center">
                <a
                  href="https://en.wikipedia.org/wiki/Therapy"
                  className="hover:text-purple-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300 rounded"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-info-circle text-white text-lg leading-lg mr-2" />
                  About Therapy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
