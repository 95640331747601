// src/contexts/UserContext.js
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

// Create UserContext
const UserContext = createContext();

/**
 * Custom hook to access UserContext
 * @returns {Object} - The user progress and related functions.
 */
export function useUserProgress() {
  return useContext(UserContext);
}

/**
 * UserProvider Component to manage and provide user progress state.
 * @param {Object} children - The children components to wrap.
 */
export function UserProvider({ children }) {
  const [progress, setProgress] = useState({
    creativity: 0,
    logic: 0,
    memory: 0,
    communication: 0,
    mindfulness: 0,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetch user progress from a simulated backend.
   * You can replace this with actual API or local storage logic.
   */
  const fetchProgress = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // Simulated backend response
      const fetchedProgress = {
        creativity: 80,
        logic: 50,
        memory: 60,
        communication: 90,
        mindfulness: 70,
      };
      setProgress(fetchedProgress);
    } catch (error) {
      console.error("Failed to fetch progress:", error);
      setError("Could not load progress.");
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Update progress for a specific category.
   * @param {string} category - The category to update.
   * @param {number} value - The new value for the category.
   */
  const updateProgress = (category, value) => {
    setProgress((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  // Fetch progress on component mount
  useEffect(() => {
    fetchProgress();
  }, [fetchProgress]);

  const contextValue = {
    progress,
    updateProgress,
    loading,
    error,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
