// src/layouts/MainLayout.js
import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import FlowerProgress from "../components/FlowerProgress";
import IndexNavbar from "../components/Navbars/IndexNavbar";
import NotificationsPanel from "../components/NotificationsPanel"; // Import NotificationsPanel

export default function MainLayout() {
  const { currentUser, logout } = useAuth();

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Navbar */}
      <IndexNavbar />

      {/* Header */}
      <header className="bg-blue-600 text-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">
            <Link to="/">The Bloom Within</Link>
          </h1>
          <nav className="space-x-4">
            <Link to="/">Home</Link>
            <Link to="/journal">Journal</Link>
            <Link to="/calendar">Calendar</Link>
            <Link to="/modes">Modes</Link>
            {currentUser ? (
              <button onClick={logout} className="underline">
                Logout
              </button>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
        </div>
      </header>

      {/* Main Content with Notifications */}
      <main className="grow p-8">
        <div className="flex flex-col md:flex-row">
          {/* Left Content: Page Content */}
          <div className="flex-grow">
            <Outlet />
          </div>

          {/* Right Content: Notifications Panel */}
          <div className="w-full md:w-1/3 p-4">
            <NotificationsPanel /> {/* Use the new component */}
          </div>
        </div>
      </main>

      {/* Footer with Flower Progress Tracker */}
      <footer className="bg-gray-800 text-white p-4 text-center">
        <FlowerProgress />
        <p>&copy; 2024 The Bloom Within. All Rights Reserved.</p>
      </footer>
    </div>
  );
}
